@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800&family=Inter:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-white dark:bg-background text-gray-600 dark:text-gray-300 font-body;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-heading font-bold text-gray-900 dark:text-white;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  html {
    scroll-behavior: smooth;
  }

  section[id] {
    scroll-margin-top: 6rem;  /* Accounts for sticky header + some breathing room */
  }
}

@layer components {
  .hover-link {
    @apply transition-colors duration-200 ease-in-out hover:text-accent duration-1000;
  }
}
